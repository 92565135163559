import ValidateBookingResponse from '../../../interfaces/booking/responses/ValidateBookingResponse';
import config from '../../../config';
import AvailableSlot from '../../../models/booking/AvailableSlot';
import bookingService from '../../../services/booking.service';
import BookingState from './booking.state';
import ImageHelper from '../../../helpers/image-helper';

export default {

  state: {
    slot: null,
    description: '',
    appointmentId: 0,
    images: [],
  } as BookingState,

  mutations: {
    saveBooking: (
      state: BookingState,
      payload: any,
    ) => {
      Object.assign(state, {
        ...state,
        ...payload
      });
    },

    setSymptomDescription: (
      state: BookingState,
      description: string
    ) => {
      state.description = description;
    },

    clearSymptomDescription: (
      state: BookingState,
    ) => {
      state.description = '';
    },

    setImages: async (
      state: BookingState,
      images: Array<File>
    ) => {
      const imageArray = [];
      for (let i = 0; i < Object.keys(images).length; i += 1) {
        // eslint-disable-next-line
        const result = await ImageHelper.convertToBase64String(images[i]);
        imageArray.push(result);
      }

      state.images = imageArray;
    },

    clearImages: (
      state: BookingState,
    ) => {
      state.images = [];
    },

    clearBooking: (
      state: BookingState,
    ) => {
      Object.assign(state, {
        ...state,
        slot: null,
        appointmentId: 0,
      });
    },

    clearAppointmentId: (
      state: BookingState,
    ) => {
      state.appointmentId = 0;
    },
  },

  actions: {
    reserveSlot({ commit, rootState, state }: any, slot: AvailableSlot) {
      const {
        countryId,
        device,
      } = rootState.client;
      const partnerId = rootState.partner.id;

      const payload = {
        caseTypeID: rootState.service.selected.id || config.VET_SERVICE_ID,
        partnerId,
        bookingID: slot.id,
        'country_id': countryId,
        device,
        widgetSessionUuid: `widget_${  rootState.auth.sessionId}`,
        appointmentID: state.appointmentId ? state.appointmentId : 0,
      }

      return bookingService.reserveSlot(payload).then((res: any) => {
        commit('saveBooking', {
          appointmentId: res.appointment_id,
          slot,
        });
        return true;
      }).catch(() => {
        return false;
      });
    },

    setSymptomDescription({ state }: any) {
      return bookingService.setSymptomDescription(
        state.description,
        state.appointmentId,
      ).catch(console.error);
    },

    clearSymptomDescription({ commit }: any) {
      return commit('clearSymptomDescription');
    },

    confirmBooking({ commit, state }: any) {
      return bookingService.confirmBooking({
        'skip_checkout': true,
        'appointment_id': state.appointmentId,
        'widget_checkout': true,
      }).then((res: any) => {
        commit('clearAppointmentId'); // Clear the appointmentId after the appointment is confirmed, so that it's not re-used
        return res;
      }).catch(console.error);
    },

    storeImages({ state, rootState }: any) {
      state.images.forEach((image: string) => {
        const fd = new FormData();
        fd.append('image', ImageHelper.restoreImageFromBase64String(image));

        bookingService.uploadImages(rootState.partner.id, rootState.user.id, state.appointmentId, fd)
          .catch(console.error);
      });
    },

    async validateBooking({state}: any): Promise<ValidateBookingResponse> {
      return bookingService.validateBooking(state.appointmentId)
    },
  },
}
