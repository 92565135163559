







































import Vue from 'vue'
import { mapGetters, mapActions, mapState } from 'vuex'
import BookingContainer from '@/components/containers/BookingContainer.vue'
import DownloadAppBadges from '@/components/misc/DownloadAppBadges.vue'
import PageHeader from '@/components/modal/PageHeader.vue'

export default Vue.extend({
  name: 'Done',
  components: {
    PageHeader,
    DownloadAppBadges,
    BookingContainer,
  },
  computed: {
    ...mapGetters('animal', ['hasMultipleAnimals']),
    ...mapState('animal', { selectedAnimal: 'selected' }),
    ...mapState(['booking', 'partner', 'user']),
  },
  mounted() {
    this.$customEvents.onBookingComplete(this.selectedAnimal, this.user, this.booking);
    this.$trackingEvents.bookingSuccessfulWidget(this.selectedAnimal, this.partner, this.booking);
    this.storeImages();
    this.clearSymptomDescription();
  },
  methods: {
    ...mapActions('booking', ['storeImages', 'clearSymptomDescription']),
    openStore(store: string): void {
      this.$trackingEvents.clickedAppstoreBadgeWidget(store);
      window.open(this.getStoreUrl(store), '_blank');
    },
    navigateToStartView(): void {
      this.$trackingEvents.clickedTimeNaSelectNewWidget();
      this.$emit('navigate', { name: 'select-animal', clearHistory: true });
    },
    getStoreUrl(store: string): string {
      if (store === 'app-store') {
        return 'https://apps.apple.com/app/firstvet/id1349241101';
      }
      if (store === 'google-play') {
        return 'https://play.google.com/store/apps/details?id=com.firstvet.firstvetlocalized';
      }
      return '';
    }
  },
})
