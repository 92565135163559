import store from '../../store';
import ManagePartnerCustomerResponse from '../../../interfaces/auth/responses/ManagePartnerCustomerResponse';
import CountryCode from '../../../models/CountryCode';
import CookiesPolicy from '../../../models/CookiesPolicy';
import PrivacyPolicy from '../../../models/PrivacyPolicy';
import TermsOfService from '../../../models/TermsOfService';
import authService from '../../../services/auth.service'
import AuthState from './auth.state';

export default {

  state: AuthState.create(
    '',
    [],
    ''
  ),

  getters: {
    getCountryCodeId: (
      state: AuthState,
    ) => (code: string): number => {
      const selectedCountryCode = state.countryCodes.find((countryCode: CountryCode) => countryCode.code === code);
      return selectedCountryCode ? selectedCountryCode.id : 0;
    },
    getSessionId: (
      state: AuthState,
    ) => (): string => {
      return state.sessionId;
    }
  },

  mutations: {
    setCountryCodes: (
      state: AuthState,
      countryCodes: Array<CountryCode>,
    ) => {
      state.countryCodes = countryCodes;
    },

    setTermsOfService: (
      state: AuthState,
      termsOfService: TermsOfService,
    ) => {
      state.termsOfService = termsOfService;
    },

    setPrivacyPolicy: (
      state: AuthState,
      privacyPolicy: PrivacyPolicy,
    ) => {
      state.privacyPolicy = privacyPolicy;
    },

    setCookiesPolicy: (
      state: AuthState,
      cookies: CookiesPolicy,
    ) => {
      state.cookies = cookies;
    },

    setJWT: (
      state: AuthState,
      jwt: string,
    ) => {
      state.jwt = jwt;
    },

    setSessionId: (
      state: AuthState,
      sessionId: string,
    ) => {
      state.sessionId = sessionId;
    },
  },

  actions: {
    fetchCountryCodes({ commit, rootState }: any) {
      authService.fetchCountryCodes()
        .then((countryCodes: Array<CountryCode>) => {
          commit('setCountryCodes', countryCodes);
          if (!rootState.user.phone.countryCode.length) {
            const countryCode = countryCodes.find((code): boolean => code.countryId === rootState.client.countryId);
            const { code } = countryCode || {};
            commit('user/setPhone', { countryCode: code }, { root: true });
          }
        });
    },

    fetchTermsOfService({ commit, rootState }: any) {
      commit('setTermsOfService', {
        isLoading: true,
        title: '',
        html: '',
      });

      return authService.fetchTermsOfService(rootState.client.countryId)
        .then((res) => {
          commit('setTermsOfService', res)
        });
    },

    fetchPrivacyPolicy({ commit, rootState }: any) {
      commit('setPrivacyPolicy', {
        isLoading: true,
        title: '',
        html: '',
      });

      return authService.fetchPrivacyPolicy(rootState.client.countryId)
        .then((res) => {
          commit('setPrivacyPolicy', res)
        });
    },

    fetchCookiesPolicy({ commit, rootState }: any) {
      commit('setCookiesPolicy', {
        isLoading: true,
        html: '',
      });

      return authService.fetchCookiesPolicy(rootState.client.countryId)
        .then((res) => {
          commit('setCookiesPolicy', res)
        });
    },

    managePartnerCustomer({ commit, rootState }: any) {
      return authService.managePartnerCustomer({
        'mobile_phone': rootState.user.phone.number,
        'country_code': rootState.user.phone.countryCode,
        'country_id': rootState.client.countryId,
        'email': rootState.user.email,
        'appointment_id': rootState.booking.appointmentId,
        'first_name': rootState.user.firstName,
        'last_name': rootState.user.lastName,
      }).then((res: ManagePartnerCustomerResponse) => {
        const { user } = res;
        store.commit('user/set', {
          id: user.id,
          email: user.email,
          firstName: user.first_name,
          lastName: user.last_name,
        });
        return true;
      }).catch(() => {
        return false;
      });
    },

    requestConfirmationCode({ getters, rootState }: any) {
      return authService.requestConfirmationCode({
        'mobile_phone': rootState.user.phone.number,
        'country_code_id': getters.getCountryCodeId(rootState.user.phone.countryCode),
        'code_length': '4',
      }).then((res: any) => {
        return res;
      }).catch(console.error);
    },

    verifyConfirmationCode({ commit, getters, rootState }: any, code: number) {
      return authService.verifyConfirmationCode({
        'mobile_phone': rootState.user.phone.number,
        'code': code,
        'country_code_id': getters.getCountryCodeId(rootState.user.phone.countryCode),
      }).then((res: any) => {
        const { jwt } = res;
        commit('setJWT', jwt);
        return res;
      }).catch(console.error);
    },
  },
}